import React from "react";
import { CourseHelper, dateHelper, enrollmentService, networkErrorHelper } from "libs";
import { COURSE_TYPES, ENROLLMENT_STEPS, PAYMENT_STATUS_LABEL } from "const";

export class EnrollStart {

    title = 'INFO';
    iconName = 'it-pa';

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.course = payload.course;
    }

    async onNextStep() {

        if (CourseHelper.isPercorsoAbilitante(this.course.code)) {
            await this.updateRemote();
        }


        return true;
    }

    async updateRemote() {


        this.enrollment.currentStep = ENROLLMENT_STEPS.END;
        this.enrollment.dateEnd = new Date();
        this.enrollment.dateSign = new Date();
        this.enrollment.syncState = 'selfApproved';

        await enrollmentService.updateEnrollment(this.enrollment)
            .then(() => {

                this.canNext = true;
            })
            .catch(errors => {
                console.log(errors);
                this.canNext = false;
                networkErrorHelper.notify(errors);
            });

    }

    renderCourse() {

        const dateStart = dateHelper.toITDateTime(this.enrollment.dateStart);

        const paymentStatus = PAYMENT_STATUS_LABEL[this.enrollment.paymentStatus];
        return (<>
            <p>Di seguito sono riportati i dati della tua domanda, per completare premere il tasto <b>AVANTI</b>:</p>
            <ul>
                <li><b>Numero:</b> {this.enrollment.orderNumber}</li>
                <li><b>Data inizio procedura:</b> {dateStart}</li>
                <li><b>Stato della domanda:</b> da completare</li>
                <li><b>Modalità di pagamento: </b> {this.enrollment.paymentType}</li>
                <li><b>Importo: </b> euro {this.enrollment.importToPay}</li>
                <li><b>Stato del pagamento:</b>  {paymentStatus}</li>
                <li><b>Firma: </b> da apporre</li>
            </ul>
            <p>Per completare la procedura premi sul tasto <b>AVANTI</b></p>
        </>);
    }

    renderRequest() {

        const dateStart = dateHelper.toITDateTime(this.enrollment.dateStart);


        return (<>
            <p>Di seguito sono riportati i dati della tua richiesta, per completare premere il tasto <b>AVANTI</b>:</p>
            <ul>
                <li><b>Numero:</b> {this.enrollment.orderNumber}</li>
                <li><b>Data inizio procedura:</b> {dateStart}</li>
                <li><b>Stato della domanda:</b> da completare</li>
            </ul>
            <p>Per completare la procedura premi sul tasto <b>AVANTI</b></p>
        </>);
    }

    render() {

        return (this.course.courseType === COURSE_TYPES.REQUEST)
            ? this.renderRequest()
            : this.renderCourse();
    }
}