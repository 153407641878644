import React from "react";
import {
  MCheckbox,
  MInput,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";

export class VincitoreConcorso extends ValidatedForm {
  emptyFields = {
    istitution: "",
    dataConcorso: "",
    description: "",
    classeConcorso: this.props.competitionClass,
    checkVC: false,
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  state = {
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  FIELDS_GROUP = [
    [
      {
        id: "checkVC000",
        field: "checkVC",
        component: MCheckbox,        
        className: "col-md-12 mb-5",
        label: "Dichiaro di essere vincitore del concorso PNNR1 (D.D.2575/2023), e di aver sottoscritto un contratto di lavoro subordinato a tempo determinato.",
      },
    ],
    [
      {
        id: "istitution01",
        field: "istitution",
        label: "Ufficio Scolastico Regionale (USR) ",
        component: MInput,
        className: "col-md-12",
        infoText: "ufficio della regione che ha bandito il concorso",
      },
    ],[
      {
        id: "dataConcorso01",
        field: "dataConcorso",
        label: "Concorso indetto in data",
        component: MInput,
        type: "date",
        className: "col-md-5",
        infoText: "Inserire la data",
      },
      {
        id: "description01",
        field: "description",
        label: "Decreto concorso numero",
        component: MInput,
        className: "col-md-5"
      },
      {
        id: "classeConcorso00",
        field: "classeConcorso",
        label: "Classe di Concorso",
        component: MInput,
        readOnly: true,
        className: "col-md-2",
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    classeConcorso: "Dato obbligatorio",
    dataConcorso: "Data non valida",
    description: "Dato obbligatorio",
    checkVC: "Dichiarazione obbligatoria",
  };

  validation = {
    istitution: (value) => value !== "",    
    dataConcorso: (value) => this.validateDataConcorso(value),
    classeConcorso: (value) => value !== "",
    description: (value) => value !== "",
    checkVC: (value) => this.validateCheck(value),
  };

  validateCheck = (value) => {
   
    if (value === "" || value === false) {
      return false;
    }
    return true;
  }

  validateDataConcorso = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return false;
    }

    //--- ---
  // not in the future    
    const today = new Date();
    if (date > today) {
      return false;
    }


    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 70 || year > currentYear) {
      return false;
    }

    return true;
  };

  onSubmit = () => {
    let isValid = true;
    isValid = this.checkValidation();
    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];

    let payload = {};

    payload = payloadBuilder(this.state);
    payload.qualificationCode = "title15_VINCITORE_CONCORSO";
    payload.qualificationType = "title15";
    payload.template = "Title15_VINCITORE_CONCORSO";
    payload.extCode = "CONC";
    payloadOther.push(payload);

    return payloadOther;
  };

  //--- ---

  render() {
    const { defaultValues } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        <form className="user mt-4">{code}</form>
      </>
    );
  }
}

