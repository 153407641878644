import React from "react";
import {
  MButton2,
  MCheckbox,
  MInput,
  MSearchUniversity,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";

export class Dichiarazione30CFU extends ValidatedForm {
  emptyFields = {
    istitution: "",
    city: "",
    dataConseguimento: "",
    votoTitolo: "",
    classeConcorso: this.props.competitionClass,
    checkVC: false
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  state = {
    showSearchUniversity: false,
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  FIELDS_GROUP = [
    [
      {
        id: "checkVC10",
        field: "checkVC",
        component: MCheckbox,
        className: "col-md-12 mb-5",
        label: "Dichiaro di avere già acquisito i 30 CFU/CFA ai sensi dell'art. 18-BIS, comma 3, primo periodo, del Decreto Legislativo n. 59 del 2017 (Allegato 3 DPCM 4 agosto 2023)",
      },
    ],
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: (e) => {
          this.searchUniversity(e);
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6 mt-4",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6 mt-4",
        readOnly: true,
      },
    ],
    [
      {
        id: "votoTitolo30cfu",
        field: "votoTitolo",
        label: "Voto",
        component: MInput,
        className: "col-md-4"
      },
      {
        id: "dataConseguimento30cfu",
        field: "dataConseguimento",
        label: "Data di Certificazione",
        component: MInput,
        type: "date",
        className: "col-md-4",
        infoText: "Inserire la data",
      },
      {
        id: "classeConcorso30cfu",
        field: "classeConcorso",
        label: "Classe di Concorso",
        component: MInput,
        readOnly: true,
        className: "col-md-4",
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    city: "Dato obbligatorio",
    dataConseguimento: "Inserire la data non precedente al 04/08/2023",
    votoTitolo: "Dato obbligatorio",
    classeConcorso: "Dato obbligatorio",
    checkVC: "Dichiarazione obbligatoria",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    votoTitolo: (value) => value !== "",
    classeConcorso: (value) => value !== "",
    checkVC: (value) => this.validateCheck(value),
  };

  validateCheck = (value) => {
   
    if (value === "" || value === false) {
      return false;
    }
    return true;
  }

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 70 || year > currentYear) {
      return false;
    }

    //---
    
    const minDate = new Date("2023-08-04");
    if (date < minDate) {
      return false;
    }
    
    const today = new Date();
    if (date > today) {
      return false;
    }

    return true;
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
    });
  };

  onSubmit = () => {
    let isValid = true;
    isValid = this.checkValidation();
    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];

    let payload = {};

    payload = payloadBuilder(this.state);
    payload.qualificationType = "title14";
    payload.qualificationCode = "title14_30CFU";

    payload.template = "Title14_30CFU";
    payload.extCode = "CORSPERF_ATTAFAMUNI";
    payload.uniCode = this.state.university.uniCode;
    payloadOther.push(payload);

    return payloadOther;
  };

  //--- ---

  render() {
    const { defaultValues, showSearchUniversity } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>       
        {showSearchUniversity && (
          <div className="border p-3 rounded-lg">
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
              isCFU={true}
            />
          </div>
        )}

        <form className="user mt-4">{code}</form>
      </>
    );
  }
}
