import React, { Component } from "react";
import { BoxSuccess, MnemoLoading, MSection } from "components/misc";
import { ROUTES } from "const";
import { courseService, networkErrorHelper, stringHelper } from "libs";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import {
    Row, Col, Container,
    Button,
    AccordionHeader,
    AccordionBody
} from "design-react-kit";
import { MSearch } from "components/forms";

class CourseBrowser60CFU extends Component {

    _class1Ref = null;

    LABEL_UNIVERSITY = {
        iul: 'Università IUL',
        unicamillus: 'Università Unicamillus',
    };

    state = {

        loading: true,
        currentRegion: '',
        currentCompetitionClass: '',

        courseCompetitionClass: [],
        courseFiltered: [],

        payloadCompetitionClass: [],
        payloadRegion: [],

        filterRegionOpen: false,
        filterRegionApplied: false,

        filterCCOpen: false,
        filterCCApplied: false,

    }

    componentDidMount() {

        this.setState({ loading: true });
        const { company } = this.props.match.params;

        // if company is not empty and is not in LABEL_UNIVERSITY, then go to 404 page
        if (company && !this.LABEL_UNIVERSITY[company]) {
            this.props.history.push(ROUTES.PAGE_404);
        }

        this.loadCompetionClasses(company);
        this._class1Ref = React.createRef();
    }

    loadCompetionClasses(company) {

        courseService
            .listByCompetitionClass('ABILITAZIONI60CFU_BANDO', company)
            .then(({ data }) => {

                const { courseCompetitionClass } = data.payload;

                const payloadCompetitionClass = [];
                const payloadRegion = [];

                // fill the payload for the search component
                courseCompetitionClass.forEach((item) => {

                    const foundCC = payloadCompetitionClass.find(el => el.value === item.competitionClass);
                    if (!foundCC) {
                        payloadCompetitionClass.push({ value: item.competitionClass, label: item.competitionClass + ' - ' + item.note });
                    }

                    const foundRegion = payloadRegion.find(el => el.value === item.region);
                    if (!foundRegion) {
                        payloadRegion.push({ value: item.region, label: item.region.toUpperCase() });
                    }

                    // order paylodRegion based on value
                    payloadRegion.sort((a, b) => {
                        if (a.value < b.value) return -1;
                        if (a.value > b.value) return 1;
                        return 0;
                    });

                    // order paylodCompetitionClass based on value
                    payloadCompetitionClass.sort((a, b) => {
                        if (a.value < b.value) return -1;
                        if (a.value > b.value) return 1;
                        return 0;
                    });
                });

                this.setState({
                    courseCompetitionClass,
                    courseFiltered: courseCompetitionClass,
                    payloadCompetitionClass, payloadRegion,
                    loading: false
                });

            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });

    }

    toggleCourse = id => {


        this.setState({
            ...this.defaultState,
            [`toggleCourse${id}`]: !this.state[`toggleCourse${id}`],
        });

    }

    toggleFilter = id => {

        this.setState({ filterRegionApplied: false, filterCCApplied: false });

        switch (id) {

            case 'filterRegionOpen':
                this.setState({ filterCCOpen: false, filterRegionOpen: true });
                this.applyFilter('', '');
                break;

            case 'filterCCOpen':
                this.setState({ filterCCOpen: true, filterRegionOpen: false });
                this.applyFilter('', '');
                break;
            default:
        };
    }

    openCourse(code) {
        window.open(`${ROUTES.COURSE_LANDINGPAGE}\\${code}`, '_blank');
    }

    changeCompetitionClass = (_, value) => {

        const newCompetitionClass = (value) ? value.value : '';
        this.applyFilter(newCompetitionClass, '');

        this.setState({ filterCCApplied: true });
    }

    changeRegion = (_, value) => {

        const newRegion = (value) ? value.value : '';
        this.applyFilter('', newRegion);
        this.setState({ filterRegionApplied: true });
    }

    filterReset() {

        this.applyFilter('', '');
        this.setState({ filterRegionOpen: false, filterCCOpen: false, filterRegionApplied: false, filterCCApplied: false });
        if (this._class1Ref && this._class1Ref.current) {
            this._class1Ref.current.clearValue();
        }
    }

    applyFilter(competitionClass, region) {

        const { courseCompetitionClass } = this.state;
        let courseFiltered = courseCompetitionClass;

        if (competitionClass) {
            courseFiltered = courseFiltered.filter(course => course.competitionClass === competitionClass);
        }

        if (region) {
            courseFiltered = courseFiltered.filter(course => course.region === region);
        }

        this.setState({
            currentCompetitionClass: competitionClass,
            currentRegion: region,
            courseFiltered,
        });

        return courseFiltered;
    }

    renderFilterSection() {

        const {
            payloadCompetitionClass, payloadRegion,
            filterRegionOpen, filterCCOpen
        } = this.state;

        return (
            <>
                <MSection className="mb-3">
                    <Row>
                        <Col size="12" md="6">
                            <AccordionHeader active={filterCCOpen} onToggle={() => this.toggleFilter('filterCCOpen')}>
                                <h6>Ricerca per Classe di Concorso</h6>
                            </AccordionHeader>
                            <AccordionBody active={filterCCOpen}>
                                <MSearch
                                    key="class-select"
                                    field="class-select"
                                    id="class-select"
                                    name="class-select"
                                    placeHolder="Inserisci o seleziona classe"
                                    payload={payloadCompetitionClass}
                                    onChange={this.changeCompetitionClass}
                                    ref={this._class1Ref}
                                />
                                <Button outline className="float-right mt-2"
                                    onClick={e => this.filterReset()}
                                >Cancella filtri</Button>
                            </AccordionBody>
                        </Col>
                        <Col size="12" md="6">
                            <AccordionHeader active={filterRegionOpen} onToggle={() => this.toggleFilter('filterRegionOpen')}>
                                <h6>Ricerca per Regione</h6>
                            </AccordionHeader>
                            <AccordionBody active={filterRegionOpen}>
                                <MSearch
                                    key="region2-select"
                                    field="region2-select"
                                    id="region2-select"
                                    name="region2-select"
                                    placeHolder="Inserisci o seleziona regione"
                                    payload={payloadRegion}
                                    onChange={this.changeRegion}
                                />
                                <Button outline className="float-right mt-2"
                                    onClick={e => this.filterReset()}
                                >Cancella filtri</Button>
                            </AccordionBody>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }

    renderCourseList() {

        const { courseFiltered, filterCCOpen, filterRegionOpen, filterRegionApplied, filterCCApplied } = this.state;
        let toShow = (courseFiltered && courseFiltered.length > 0) ? true : false;

        // const showCompany = (company) ? true : false;
        let courseList = [];

        if (filterCCOpen && filterCCApplied) {

            let currentList = [];
            let currentRegion = courseFiltered[0].region;

            courseFiltered.forEach((course, i) => {
                if (currentRegion !== course.region) {

                    if (currentList.length > 0) {
                        courseList.push(
                            <>
                                <h6 className="text-green">{currentRegion.toUpperCase()} - {course.title}</h6>
                                <ul>{currentList}</ul>
                            </>);
                    }
                    currentRegion = course.region;
                    currentList = [];

                }
                currentList.push(
                    <li key={`list-${i}`} >
                        {this.LABEL_UNIVERSITY[course.instituteCompany]}  -
                        <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/${course.code}`} target="_blank" rel="noopener noreferrer" className="text-green" >
                            <b> Iscrizione 60</b>
                        </a> -
                        <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/${stringHelper.replaceAll(course.code, '60ALL1', '30ALL2')}`} target="_blank" rel="noopener noreferrer" className="text-green" >
                            <b> Iscrizione 30</b>
                        </a>
                    </li>);

            });

            if (currentList.length > 0) {

                const course = courseFiltered[courseFiltered.length - 1];
                courseList.push(
                    <>
                        <h6 className="text-green">{currentRegion.toUpperCase()} - {course.title}</h6>
                        <ul>{currentList}</ul>
                    </>
                );
            }
        } else if (filterRegionOpen && filterRegionApplied) {

            let currentList = [];
            let currentCC = courseFiltered[0].competitionClass;
            let currentTitle = courseFiltered[0].title;

            courseFiltered.forEach((course, i) => {

                if (currentCC !== course.competitionClass) {

                    if (currentList.length > 0) {
                        courseList.push(
                            <>
                                <h6 className="text-green"> {currentTitle}</h6>
                                <ul>{currentList}</ul>
                            </>);
                    }
                    currentCC = course.competitionClass;
                    currentTitle = course.title;
                    currentList = [];
                }

                currentList.push(
                    <li >
                        {course.region.toUpperCase()} - {this.LABEL_UNIVERSITY[course.instituteCompany]}  -
                        <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/${course.code}`} target="_blank" rel="noopener noreferrer" className="text-green">
                            <b> Iscrizione 60</b>
                        </a> -
                        <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/${stringHelper.replaceAll(course.code, '60ALL1', '30ALL2')}`} target="_blank" rel="noopener noreferrer" className="text-green" >
                            <b> Iscrizione 30</b>
                        </a>
                    </li>);

            });

            if (currentList.length > 0) {

                const currentTitle = courseFiltered[courseFiltered.length - 1].title;
                courseList.push(
                    <>
                        <h6 className="text-green"> {currentTitle}</h6>
                        <ul>{currentList}</ul>
                    </>);
            }
        } else {

            let currentList = [];
            let currentCC = courseFiltered[0].competitionClass;
            let currentTitle = courseFiltered[0].title;

            courseFiltered.forEach((course, i) => {

                if (currentCC !== course.competitionClass) {

                    if (currentList.length > 0) {
                        courseList.push(
                            <>
                                <h6 className="text-green"> {currentTitle}</h6>
                                <ul>{currentList}</ul>
                            </>);
                    }
                    currentCC = course.competitionClass;
                    currentTitle = course.title;
                    currentList = [];
                }

                currentList.push(
                    <li >
                        {course.region.toUpperCase()} - {this.LABEL_UNIVERSITY[course.instituteCompany]}
                        <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/${course.code}`} target="_blank" rel="noopener noreferrer" className="text-green">
                            <b> Iscrizione 60</b>
                        </a> -
                        <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/${stringHelper.replaceAll(course.code, '60ALL1', '30ALL2')}`} target="_blank" rel="noopener noreferrer" className="text-green" >
                            <b> Iscrizione 30</b>
                        </a>
                    </li>);

            });

            if (currentList.length > 0) {

                const currentTitle = courseFiltered[courseFiltered.length - 1].title;
                courseList.push(
                    <>
                        <h6 className="text-green"> {currentTitle}</h6>
                        <ul>{currentList}</ul>
                    </>
                );
            }
        }

        return (
            <>
                {(toShow)
                    ?
                    <>{courseList}</>
                    : <BoxSuccess>La ricerca non ho prodotto alcun risultato. Cambia i filtri di ricerca.</BoxSuccess>
                }
            </>
        );
    }

    render() {

        const { loading } = this.state;
        const title = "Percorsi Abilitanti 60 e 30 CFU  D.P.C.M. 4 AGOSTO 2023";
        let courseListSection = '';
        let filterSection = '';

        if (!loading) {
            courseListSection = this.renderCourseList();
            filterSection = this.renderFilterSection();
        }

        return (
            <section className="my-4">
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <div>
                    <h1>{title}</h1>
                    <p>Per l'a.a. 2024/2025 il Ministero con i Decreti Ministeriali n. 148 del 24 Febbraio 2025,
                        n. 156 del 24 Febbraio 2025 e il n. 270 del 19 Marzo 2025 ha autorizzato le Università all'attivazione
                        dei Percorsi universitari di formazione iniziale dei docenti delle scuole secondarie di primo e secondo grado.</p>
                    <p>Iscrizioni aperte per:</p>
                    <ul><li>Corsi 60 e 30 cfu all. 2 (a cui si accede con Laurea)</li></ul>
                    <hr/>
                    <p>Esami entro il 31 Agosto 2025 - <a href="https://www.formazionedocenti.it/page/faq-60-30-36-cfu">FAQ  60, 30 e 36 CFU</a></p>
                </div>
                <hr />
                {(loading) ? <MnemoLoading /> : (<>
                    <Container>
                        {filterSection}
                        <MSection className="mb-3">
                            <Row>
                                <Col size="12">
                                    {courseListSection}
                                </Col>
                            </Row>
                        </MSection>
                    </Container>
                </>)}
                <p>Per le iscrizioni ai corsi A022, A026, A028, A031, A047, A048, A049, A050, A060 e B012 erogati solo nella regione Lazio da UniCamillus 
                    <a href="https://unicamillus.org/corsi/formazione-insegnanti/60-30-cfu-dpcm/" target="_blank"> cliccare qui</a></p>
                <hr />
                <h2>Attività Formativa</h2>
                <p><b>Lezioni</b>: in presenza nella regione di attivazione del corso presso la sede accreditata e on-line in modalità telematica sincrona, ad eccezione dei tirocini e dei laboratori, con una percentuale massima del 50% del totale.</p>
                <p><b>Prova finale (prova scritta + lezione simulata):</b> nella sede Regionale dove è attivo il corso.</p>
                <p>Ammissione alla prova finale: è necessaria una percentuale minima di presenza alle attività formative pari al 70% per ogni attività formativa. </p>
                <p><b>Sedi Esami finali: </b>in una o più sedi della Regione dove è stato accreditato il corso </p>
                <p><b>Calendario lezioni: </b>a breve disponibile</p>
                <hr />
                <h2>Costi</h2>
                <p><b>PERCORSO 60 CFU ALL. 1 (destinato ai Laureati e Laureandi):</b></p>
                <ul>
                    <li>contributo pari a € 100,00 per la presentazione della domanda di ammissione;</li>
                    <li>da € 2.400 a €2.500 rateizzabili fino a n. 3/4 versamenti;</li>
                </ul>
                <p>(*) come previsto dal DPCM 4 Agosto 2023 per accedere alla prova finale é previsto un versamento di € 150 (Centocinquanta/00).</p>
                <p><b>PERCORSO 30 CFU ALL.2 (destinato a chi ha svolto tre anni di servizio d'insegnamento)</b></p>
                <ul>
                    <li>contributo pari a € 100,00 per la presentazione della domanda di ammissione;</li>
                    <li>€ 1.900 rateizzabili fino a n. 3/4 versamenti.</li>
                </ul>
                <p>(*) come previsto dal DPCM 4 Agosto 2023 per accedere alla prova finale é previsto un versamento di € 150 (Centocinquanta/00).</p>
                <hr />
                <p>E-mail: <a href="mailto:abilitazione@formazionedocenti.it">abilitazione@formazionedocenti.it</a></p>
            </section>

        );
    }
}


export default withRouter(CourseBrowser60CFU);