import React from "react";

import {
  MButton2,
  MCheckbox,
  MInput,
  MLabel,
  MSearchUniversity,
  MSelect,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import dayjs from "dayjs";
import { MnemoCallout } from "components/misc";
import { FormRef2TitleStep1 } from "./FormRef2TitleStep1";

export class FormTitleStep1270 extends ValidatedForm {
  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  emptyFields = {
    extCode: "",
    istitution: "",
    aa: "",
    dataConseguimento: "",
    baseVote: 110,
    votoTitolo: "",
    lode: false,
    city: "",
    nation: "",
    contactName: "",
    contactPhone: "",
    contactFax: "",
    contactEmail: "",
    contactPec: "",
  };

  state = {
    currentExtCode: "",
    showSearchUniversity: false,
    university: {},
    _formRef2_1_1: null,
    ...this.emptyFields,
    defaultValues: [],
  };

  ERROR_MESSAGES = {
    extCode: "Dato obbligatorio",
    istitution: "Dato obbligatorio",
    dataConseguimento: "Dato non valido",
    aa: "Inserire l'anno accademico in formato AAAA/AAAA",
    baseVote: "Dato obbligatorio e numerico",
    votoTitolo: "Dato obbligatorio e numerico (min. 60, max. 110)",
    city: "Dato obbligatorio",
  };

  validation = {
    extCode: (value) => value !== "",
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    votoTitolo: (value) => this.validateVoteBaseVote(value),
    baseVote: (value) => this.validateVoteBaseVote(value),
    //lode: () => this.onChangeLode(),
  };

  onChangeExtCode = (field, value) => {
    this.setState({ currentExtCode: value });
  };

  FormTitleStep1Configurator = [
    [
      {
        field: "searchUniversity",
        component: MButton2,
        onClick: (e) => {
          this.searchUniversity(e);
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-5 ",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        field: "city",
        label: "Città Università",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        field: "istitution",
        label: "Università",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        field: "extCode",
        label: "Tipo di laurea",
        component: MSelect,
        payload: {
          options: [
            { value: "", label: "Seleziona il tipo di laurea" },
            {
              value: "LAU_02_270",
              label: "Laurea DM 270/2004 magistrale biennale",
            },
            {
              value: "LAU_CU_270",
              label: "Laurea DM 270/2004 magistrale a ciclo unico",
            },
          ],
        },
        onChange: this.onChangeExtCode,
        className: "col-md-12",
      },
    ],
    [
      {
        field: "votoTitolo",
        label: "Voto",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        field: "baseVote",
        label: "Base Voto",
        component: MInput,
        type: "text",
        className: "col-md-4",
        readOnly: true,
      },
      {
        id: "lode",
        name: "lode",
        field: "lode",
        label: "Lode",
        component: MCheckbox,
        className: "col-md-4",
        infoText:
          "puoi selezionare la lode quando Voto e Base Voto sono uguali",
        onChange: () => this.onChangeLode(),
      },
    ],
    [
      {
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-6",
      },
      {
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "preferExamPresence",
        label: "Informazioni facoltative:",
        infoText:
          "Informazioni per la verifica dei dati ex articolo 15, comma 1, punto e della legge 183/2011. Recapiti della segreteria competente.",
        component: MLabel,
        className: "col-md-12",
      },
    ],
    [
      {
        field: "contactName",
        label: "Denominazione",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        field: "contactPhone",
        label: "Telefono",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        field: "contactFax",
        label: "Fax",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "contactEmail",
        label: "Email",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        field: "contactPec",
        label: "Pec",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
  ];

  //--- ---

  validateVoteBaseVote = (value) => {
    this.onChangeLode();

    if (value === "") {
      return false;
    }

    if (isNaN(value)) {
      return false;
    }

    if (value < 60 || value > 110) {
      return false;
    }
    
    return true;
  };

  validateAA = (value) => {
    //--- reset dataConseguimento ---
    this.setState({ dataConseguimento: "" });

    value=value.trim();
    let formAA = /^(\d{4})\/(\d{4})$/.test(value);
    
    if (!formAA) {
      return false;
    } else {
      formAA = value.split("/");
      var dbAA = [];

      const currentYear = new Date().getFullYear();
      if (parseInt(formAA[1]) > currentYear) {
        return false;
      }

      if (parseInt(formAA[0]) + 1 === parseInt(formAA[1])) {
        //--- ---
        if (
          this.props.item.aaStart !== null &&
          this.props.item.achievementDateEnd === null
        ) {
          // a partire dall'anno aa
          dbAA = this.props.item.aaStart.split("/");
          if (parseInt(formAA[1]) < parseInt(dbAA[1])) {
            return false;
          }

          return true;
        }

        //--- ---
        if (
          this.props.item.aaStart !== null &&
          this.props.item.achievementDateEnd !== null
        ) {
          dbAA = this.props.item.aaStart.split("/");
          const DbdateY = dayjs(this.props.item.achievementDateEnd).format(
            "YYYY"
          );
          if (
            parseInt(formAA[1]) > parseInt(DbdateY) ||
            parseInt(formAA[1]) < parseInt(dbAA[1])
          ) {
            return false;
          }

          return true;
        }

        //--- ---
        if (this.props.item.aaEnd !== null) {
          //entro l'anno aa
          dbAA = this.props.item.aaEnd.split("/");
          if (parseInt(formAA[1]) > parseInt(dbAA[1])) {
            return false;
          }

          return true;
        }

        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    let regex = /^\d{4}-\d{2}-\d{2}$/;
    if (value === "" || !value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const formDate = new Date(value);

    //in the future not valid
    if (formDate > today) {
      return false;
    }

    //--- ---
    if (Number.isNaN(formDate.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12 || day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }

    //--- guard on compliant AA --
    if (this.state.aa?.value) {
      const aa = this.state.aa.value.split("/");
      const firstDate = new Date(`${parseInt(aa[1])}-03-01`);
      const secondDate = new Date(`${parseInt(aa[1]) + 1}-04-30`);

      if (formDate < firstDate || formDate > secondDate) {
        return false;
      }
    }

    /* CALOGERO: perchè questo controllo?
    if (this.props.item.aaStart !== null) {
      let aa = this.props.item.aaStart.split("/");
      const firstDate = new Date(`${parseInt(aa[1])}-03-01`);
      const secondDate = new Date(`${parseInt(aa[1]) + 1}-04-30`);

      if (formDate < firstDate || formDate > secondDate) {
        return false;
      }
    }
*/
    //--- ---
    if (this.props.item.achievementDateStart !== null) {
      const formDate = new Date(value);
      const Dbdate = new Date(
        dayjs(this.props.item.achievementDateStart).format("YYYY-MM-DD")
      );

      if (formDate < Dbdate) {
        return false;
      }

      return true;
    }

    //--- ---
    if (this.props.item.achievementDateEnd !== null) {
      const formDate = new Date(value);
      const Dbdate = new Date(
        dayjs(this.props.item.achievementDateEnd).format("YYYY-MM-DD")
      );

      if (formDate > Dbdate) {
        return false;
      }

      return true;
    }

    /*if (this.props.item.aaEnd !== null) {
        //entro l'anno aa
        const dbAA = this.props.item.aaEnd.split("/");
        const formAA = value.split("/");
        if (parseInt(formAA[1]) > parseInt(dbAA[1])) {
          return false;
        }
      }*/

    if (
      this.props.item.achievementDateStart === null &&
      this.props.item.achievementDateEnd === null
    ) {
      return true;
    }
  };

  onChangeLode = () => {
    let { votoTitolo, baseVote } = this.state;

    if (typeof baseVote === "object") {
      baseVote = baseVote.value;
    }

    if (
      isNaN(votoTitolo.value) ||
      isNaN(baseVote) ||
      votoTitolo.value === "" ||
      baseVote === "" ||
      parseInt(votoTitolo.value) !== parseInt(baseVote)
    ) {
      this.setState({ lode: { value: false, isValid: true } });
      return false;
    }

    return true;
  };

  componentDidMount() {
    const defaultValues = this.emptyFields;
    const _formRef2_1_1 = React.createRef();
    this.setState({ defaultValues: defaultValues, _formRef2_1_1 });
  }

  onSubmit = () => {
    const isValidA = this.checkValidation();
    let isValidB = true;

    const { _formRef2_1_1 } = this.state;
    if (_formRef2_1_1 && _formRef2_1_1.current) {
      isValidB = _formRef2_1_1.current.checkValidation();
    }

    return isValidA && isValidB;
  };

  getFormLabel = () => {
    return this.props.qualificationType;
  };

  getPayload = () => {
    const payloads = [];
    this.payload = payloadBuilder(this.state);
    this.payload.description = this.props.qualificationType;
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.qualificationCode = "title11";
    this.payload.template = this.payload.extCode;

    if (!this.payload.nation) {
      this.payload.nation = "Italia";
    }

    this.payload.uniCode = this.state.university.uniCode;
    payloads.push(this.payload);

    const { _formRef2_1_1 } = this.state;
    if (_formRef2_1_1 && _formRef2_1_1.current) {
      payloads.push(_formRef2_1_1.current.getPayload());
    }

    return payloads;
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
    });
  };

  //--- ---

  render() {
    const {
      defaultValues,
      showSearchUniversity,
      _formRef2_1_1,
      currentExtCode,
    } = this.state;

    const fields = this.FormTitleStep1Configurator;
    const { code } = this.renderFields(fields, defaultValues, true);
    const { note } = this.props.item;

    return (
      <>
        {note && note.length > 0 && <MnemoCallout>{note}</MnemoCallout>}
        {showSearchUniversity && (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
            />
          </>
        )}

        {!showSearchUniversity && <form className="user mt-5">{code}</form>}
        {currentExtCode === "LAU_02_270" && (
          <>
            <h4>Informazioni sulla tua laurea triennale:</h4>
            <FormRef2TitleStep1 ref={_formRef2_1_1} />
          </>
        )}
      </>
    );
  }
}
