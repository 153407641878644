import React from "react";

import {
  MButton2,
  MInput,
  MLabel,
  MSearchSchool,
  MSelect,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import dayjs from "dayjs";
import { MnemoCallout } from "components/misc";

export class FormTitleITPStep1 extends ValidatedForm {
  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  emptyFields = {
    istitution: "",
    aa: "",
    dataConseguimento: "",
    baseVote: "",
    votoTitolo: "",
    city: "",
    nation: "",
    contactName: "",
    contactPhone: "",
    contactFax: "",
    contactEmail: "",
    contactPec: "",
  };

  state = {
    showSearchSchool: false,
    university: {},
    ...this.emptyFields,
    defaultValues: [],
  };

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    dataConseguimento: "Dato obbligatorio",
    aa: "Inserire l'anno accademico in formato AAAA/AAAA",
    baseVote: "Dato obbligatorio e numerico",
    votoTitolo: "Dato obbligatorio e numerico (selezionare la base voto corretta)",
    city: "Dato obbligatorio",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    votoTitolo: (value) => this.validateVote(value),
    baseVote: (value) => this.validateBaseVote(value),
  };

  FormTitleITPStep1Configurator = [
    [
      {
        field: "searchSchool",
        component: MButton2,
        onClick: (e) => {
          this.searchSchool(e);
        },
        label: "Cerca Istituto",
        className: "col-md-12 mb-5 ",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        field: "city",
        label: "Città Istituto",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        field: "istitution",
        label: "Istituto",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        field: "votoTitolo",
        label: "Voto",
        component: MInput,
        type: "text",
        className: "col-md-4"
      },
      {
        field: "baseVote",
        label: "Base Voto",
        component: MSelect,
        payload: { options: [ {label: "Seleziona", value: ""},{label: "100", value: "100"}, {label: "60", value: "60"}] },
        type: "text",
        className: "col-md-4"
      },
    ],
    [
      {
        field: "aa",
        label: "Anno Scolastico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-6",
      },
      {
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "preferExamPresence",
        label: "Informazioni facoltative:",
        infoText:
          "Informazioni per la verifica dei dati ex articolo 15, comma 1, punto e della legge 183/2011. Recapiti della segreteria competente.",
        component: MLabel,
        className: "col-md-12",
      },
    ],
    [
      {
        field: "contactName",
        label: "Denominazione",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        field: "contactPhone",
        label: "Telefono",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        field: "contactFax",
        label: "Fax",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "contactEmail",
        label: "Email",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        field: "contactPec",
        label: "Pec",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
  ];

  //--- ---

  validateVote = (value) => {
    if (value === "") {
      return false;
    }

    if (isNaN(value)) {
      return false;
    }

    if(this.state.baseVote?.value === '' || !this.state.baseVote?.value) {
      return false;
    }

    if (this.state.baseVote?.value === "100") {
      if (value > 100 || value < 60) {
        return false;
      }
    }

    if (this.state.baseVote?.value === "60") {
      if (value > 60 || value < 36) {
        return false;
      }
    }
    
    return true;
  };

  validateBaseVote = (value) => {
    if (value === "") {
      return false;
    }

    this.setState({ votoTitolo: { value: '' } });

    return true;
  }

  validateAA = (value) => {
    
    this.setState({ dataConseguimento: { value: '' } });

    let formAA = /^(\d{4})\/(\d{4})$/.test(value);

    if (!formAA) {
      return false;
    } else {
      formAA = value.split("/");
      var dbAA = [];

      if (parseInt(formAA[1]) === parseInt(formAA[0]) + 1) {
        //--- ---
        if (
          this.props.item.aaStart !== null &&
          this.props.item.achievementDateEnd === null
        ) {
          //nell'anno aa
          dbAA = this.props.item.aaStart.split("/");
          if (parseInt(formAA[1]) < parseInt(dbAA[1])) {
            return false;
          }

          return true;
        }

        //--- ---
        if (
          this.props.item.aaStart !== null &&
          this.props.item.achievementDateEnd !== null
        ) {
          dbAA = this.props.item.aaStart.split("/");
          const DbdateY = dayjs(this.props.item.achievementDateEnd).format(
            "YYYY"
          );

          if (parseInt(formAA[1]) > parseInt(DbdateY)) {
            return false;
          }

          return true;
        }

        //--- ---
        if (this.props.item.aaEnd !== null) {
          //entro l'anno aa
          dbAA = this.props.item.aaEnd.split("/");
          if (parseInt(formAA[1]) > parseInt(dbAA[1])) {
            return false;
          }

          return true;
        }

        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {

    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const formDate = new Date(value);

    if (formDate > today) {
      return false;
    }

    //--- ---
    if (Number.isNaN(formDate.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }

    if(this.state.aa?.value){
      const aa = this.state.aa.value.split("/");
      if(parseInt(year) !== parseInt(aa[1])){
        return false;
      }
    }

    //--- ---
    if (this.props.item.achievementDateStart !== null) {
      const formDate = new Date(value);
      const Dbdate = new Date(
        dayjs(this.props.item.achievementDateStart).format("YYYY-MM-DD")
      );

      if (formDate < Dbdate) {
        return false;
      }

      return true;
    }

    //--- ---
    if (this.props.item.achievementDateEnd !== null) {
      const formDate = new Date(value);
      const Dbdate = new Date(
        dayjs(this.props.item.achievementDateEnd).format("YYYY-MM-DD")
      );

      if (formDate > Dbdate) {
        return false;
      }

      return true;
    }

    //--- ---
    if (this.props.item.aaEnd !== null) {
      //entro l'anno aa
      const dbAA = this.props.item.aaEnd.split("/");
      const formAA = value.split("/");
      if (parseInt(formAA[0]) > parseInt(dbAA[1])) {
        console.log("anno maggiore");
        return false;
      }
    }

    if (
      this.props.item.achievementDateStart === null &&
      this.props.item.achievementDateEnd === null
    ) {
      return true;
    }
  };

  componentDidMount() {
    const defaultValues = this.emptyFields;
    this.setState({ defaultValues: defaultValues });
  }

  getFormLabel = () => {
    return this.props.qualificationType;
  };

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.description = this.props.qualificationType;
    this.payload.qualificationCode = "title11";
    this.payload.template = this.props.extCode;
    this.payload.extCode = this.props.extCode;

    if (!this.payload.nation) {
      this.payload.nation = "Italia";
    }

    this.payload.uniCode = this.state.university.uniCode;

    return this.payload;
  };

  searchSchool = () => {
    this.setState({ showSearchSchool: !this.state.showSearchSchool });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchSchool: false,
      university: university,
    });
  };

  //--- ---

  render() {
    const { defaultValues, showSearchSchool } = this.state;

    const fields = this.FormTitleITPStep1Configurator;
    const { code } = this.renderFields(fields, defaultValues, true);

    return (
      <>
        <MnemoCallout>{this.props.item.note}</MnemoCallout>
        {showSearchSchool && (
          <>
            <MSearchSchool
              onChange={this.onChangeUniversity}
              searchSchool={this.searchSchool}
              isForeign={false}
            />
          </>
        )}

        {!showSearchSchool && <form className="user mt-5">{code}</form>}
      </>
    );
  }
}
