import { MCheckbox, MSelect } from "components/forms";
import { courseService } from "libs";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

export class FormCORSI3036 extends Component {

  state = {
    accept11: false,
    accept12: false,
    accept13: false,
    region: '',
    validationError: "",
    payloadRegion: [],
    course: {}
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRegion(course) {
    courseService.listCourseRegion(course.instituteCompany, course.metadata.competitionClass ).then(
      ({ data }) => {

        const { courseCompetitionClass } = data.payload;
        const payloadRegion = [{value:'', label: 'Selezionare una regione'}];

        courseCompetitionClass.forEach((item) => {
          const foundRegion = payloadRegion.find(el => el.value === item.region);
          if (!foundRegion) {
            payloadRegion.push({ value: item.region, label: item.region.toUpperCase() });
          }
        });

        // order paylodRegion based on value
        payloadRegion.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        
        this.setState({
          payloadRegion: payloadRegion,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadRemote() {

    courseService.getByCode(this.props.courseCode).then(
      (response) => {
        this.setState({
          course: response.data.payload.course,
        });

        this.loadRegion(response.data.payload.course);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getValue() {
    const pluginValue = {
      competitionClass: this.state.course.metadata.competitionClass || '',
      region: this.state.region,
      accept11: this.state.accept11,
      accept12: this.state.accept12,
      accept13: this.state.accept13,
    };

    return pluginValue;
  }

  checkValidation() {
    let validationError = "";
    let isValid = true;
    const { accept11, accept12, accept13, region } = this.state;

    if (region === '') {
      validationError += "<li>Deviselezionare una regione</li>";
      isValid = false;
    }

    if (!accept11 || !accept12 || !accept13) {
      validationError +=
        "<li>E' necessario accettare tutte le dichiarazioni.</li>";
      isValid = false;
    }
   
  if (!isValid) validationError = "<ul>" + validationError + "</ul>";
    this.setState({ validationError });

    return isValid;
  }

  onChangeRegion = (id, value) => {

    this.setState({ region: value });
  }

  onConditionClick = (id, value) => {
    this.setState({ [id]: value });
  };

  render() {

    if (!this.state.course.metadata) { return true }

    return (
      <div>
        <h3>{this.state.course.title}</h3>
        <hr />      
        <MSelect
          onChange={this.onChangeRegion}
          id="region"
          name="region"         
          payload={{ options: this.state.payloadRegion }}
          className="col-md-12"
        />
        <h5>Dichiarazioni</h5>
        <MCheckbox onChange={this.onConditionClick} id="accept11" name="accept11">
          Dichiaro di essere in possesso dei requisiti di ammissione indicati nel bando di partecipazione.
          Tali requisiti verranno ulteriormente autocertificati in fase di completamento della domanda di partecipazione.
        </MCheckbox>
        <MCheckbox onChange={this.onConditionClick} id="accept12" name="accept12">
          Dichiaro di essere in possesso dei titoli di accesso alla classe di concorso per la quale si intende
          conseguire la nuova abilitazione. Tali requisiti verranno ulteriormente autocertificati in fase di completamento della domanda di partecipazione.
        </MCheckbox>
        <MCheckbox onChange={this.onConditionClick} id="accept13" name="accept13">
          Dichiaro di non aver presentato domanda di partecipazione per percorsi relativi alla medesima
          classe di concorso in altre istituzioni.
        </MCheckbox>

        <hr />
        {this.state.validationError && (
          <>
            <span className="form-text text-danger is-invalid">
              {ReactHtmlParser(this.state.validationError)}
            </span>
          </>
        )}
      </div>
    );
  }
}
