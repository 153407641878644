import { MCheckbox, MSelect } from 'components/forms';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class FormCM30CFU extends Component {

    state = {
        accept11: false,
        accept12: false,
        accept13: false,
        competitionClass: '',
        validationError: ''
    }

    PAYLOAD_CLASS =
        {
            options: [
                { label: 'SELEZIONARE LA CLASSE DI CONCORSO', value: '' },
                { label: 'A030-Musica nella scuola secondaria di I grado', value: 'A030' },
                { label: 'AN55-Violoncello', value: 'AN55' },                
                { label: 'AB56-Strumento musicale nella scuola secondaria di I grado (Chitarra)', value: 'AB56' },
                { label: 'A053-Storia della musica', value: 'A053' },
                { label: 'AC55-Clarinetto', value: 'AC55' },
                { label: 'AC56-Strumento musicale nella scuola secondaria di I grado: Clarinetto', value: 'AC56' },
                { label: 'AG56-Strumento musicale nella scuola secondaria di I grado: Flauto', value: 'AG56' },
                { label: 'AJ55-Pianoforte', value: 'AJ55' },
                { label: 'AJ56-Strumento musicale nella scuola secondaria di I grado: Pianoforte', value: 'AJ56' },
                { label: 'AK56-Strumento musicale nella scuola secondaria di I Grado: Saxofono', value: 'AK56' },
                { label: 'AL55-Tromba', value: 'AL55' },
                { label: 'AL56-Strumento musicale nella scuola secondaria di I grado: Tromba', value: 'AL56' },
                { label: 'AM55-Violino', value: 'AM55' },
                { label: 'AM56-Strumento musicale nella scuola secondaria di I grado - Violino', value: 'AM56' },
                { label: 'AO55-Canto', value: 'AO55' }
            ]
        }

    getValue() {

        const pluginValue = {
            competitionClass: this.state.competitionClass,
            accept11: this.state.accept11,
            accept12: this.state.accept12,
            accept13: this.state.accept13,

        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        let isValid = true;
        const { accept11, accept12, accept13, competitionClass } = this.state;

        if (competitionClass === '') {
            validationError += '<li>Dovresti selezionare una classe di concorso</li>';
            isValid = false;
        }

        if (!accept11 || !accept12 || !accept13) {
            validationError += '<li>E\' necessario accettare tutte le dichiarazioni.</li>';
            isValid = false;
        }

        if (!isValid) validationError = '<ul>' + validationError + '</ul>';
        this.setState({ validationError })

        return (isValid);
    }

    onChangeCompletitionClass = (id, value) => {

        this.setState({ competitionClass: value });
    }

    onConditionClick = (id, value) => {

        this.setState({ [id]: value });
    }

    render() {
        return (
            <div>
                <h5>Classe di concorso</h5>
                <MSelect onChange={this.onChangeCompletitionClass} id="completitionClass" name="competitionClass" payload={this.PAYLOAD_CLASS}></MSelect>
                <hr />
                <h5>Dichiarazioni</h5>
                <MCheckbox onChange={this.onConditionClick} id="accept11" name="accept11" >Dichiaro di essere in possesso dei requisiti di accesso indicati all'art. 3 del Bando e che verranno dettagliatamente
                    autocertificati in fase di completamento dell'iscrizione dal sottoscritto (abilitazione o specializzazione sul sostegno)</MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept12" name="accept12">Dichiaro di essere in possesso dei titoli di studio richiesti dalla normativa vigente per l'accesso alla classe di concorso per la quale si intende conseguire la nuova abilitazione e che verranno dettagliatamente autocertificati in fase di completamento dell'iscrizione dal sottoscritto (Laurea che consente l'accesso all'insegnamento)</MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept13" name="accept13" >Dichiaro di essere consapevole che i Percorsi di formazione iniziale 30 CFU/CFA ex Art. 13 DPCM 4 agosto 2023 sono corsi universitari a frequenza obbligatoria e che la loro frequenza è incompatibile con l’iscrizione a corsi universitari che richiedano anch’essi frequenza obbligatoria DICHIARO, ai sensi dell’art. 76 D.P.R. 28 dicembre 2000, n. 445 e successive modifiche, di non essere attualmente iscritto ad altri corsi universitari a frequenza obbligatoria (escludendo da questa casistica i corsi per i quali la frequenza obbligatoria è prevista solo per attività laboratoriali e di tirocinio)</MCheckbox>

                <hr />
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{ReactHtmlParser(this.state.validationError)}</span></>)}
            </div>
        )
    }
}