import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";
import { Button } from "design-react-kit";
import { InsegnamentiMultiManager60CFU } from "./InsegnamentiMultiManager60CFU";

export class DichiarazioniUlteriori60CFU extends ValidatedForm {

  emptyFields_laurea = {
    check1: false,
    check2: false,
  };

  _formsInsegnamenti = [];

  state = {
    defaultValues: {},
    error: "",
    numInsegnamentiMultipli: [],
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP_LAUREA = [
    [
      {
        field: "check1",
        label:
          "Che il titolo conseguito è di per sé sufficiente per l’accesso alla classe di concorso " +
          this.props.competitionClass +
          " indicata nella presente candidatura, di cui alle Tabelle del D.P.R. 19/2016 come integrate/aggiornate dalla Tabella A del D.M. 259/2017 e DM 22/12/2023 GU n. 34 del 10/02/2024 Tabella A e Tabella A1;",
        component: MCheckbox,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "check2",
        label:
          "Di aver conseguito tutti i CFU per l’accesso alla classe di concorso " +
          this.props.competitionClass +
          " indicata nella presente candidatura, di cui alla Tabella A del D.P.R. 19/2016 come integrata/aggiornata dalla Tabella A del D.M. 259/2017 e DM 22/12/2023 GU n. 34 del 10/02/2024 Tabella A e Tabella A1, superando i seguenti insegnamenti:",
        component: MCheckbox,
        className: "col-md-12 pt-3",
      },
    ],
  ];

  FIELDS_GROUP_DIPLOMA = [
    [
      {
        field: "check1",
        label:
          "A. Che il titolo conseguito è di per sé sufficiente per l’accesso alla classe di concorso " +
          this.props.competitionClass +
          " indicata nella presente candidatura, di cui alle Tabelle del D.P.R. 19/2016 come integrate/aggiornate dalla Tabella A del D.M. 259/2017 e DM 22/12/2023 GU n. 34 del 10/02/2024 Tabella A e Tabella A1;",
        component: MCheckbox,
        className: "col-md-12 pt-3",
      },
    ],   
  ];

  ERROR_MESSAGES = {};
  
  ERROR_MESSAGES_LAUREA = {
    check1: (this.props.competitionClass !== "B012") ? "Selezionare una delle due opzioni" : "Opzione aobbligatoria",
    check2: (this.props.competitionClass !== "B012") ? "Selezionare una delle due opzioni" : "Opzione aobbligatoria",
  };

  validation = {
    check1: (value) => this.checkOptionSelected1(value),
    check2: (value) => this.checkOptionSelected2(value),
  };

  componentDidMount() {
    this.setState({
      defaultValues: this.emptyFields_laurea,
      ...this.emptyFields_laurea,
    });
    this.ERROR_MESSAGES = this.ERROR_MESSAGES_LAUREA;
  }

  checkOptionSelected1 = (check1) => {
    const check2 = this.getValueField("check2");
    // only one can be selected
    return (check1 && !check2) || (!check1 && check2) ? true : false;
  };

  checkOptionSelected2 = (check2) => {
    const check1 = this.getValueField("check1");
    // only one can be selected
    return (check1 && !check2) || (!check1 && check2) ? true : false;
  };

  onSubmit = () => {
    
    let isValid = this.checkValidation();
    let error = "";
    const check2 = this.getValueField("check2");

    if (isValid && check2) {
      if (this._formsInsegnamenti.length > 0) {
        this._formsInsegnamenti.forEach((form) => {
          if (form && form.current != null) {
            isValid = form.current.onSubmit();
          } else {
            console.log("ERRORE: form insegnamento not found ");
            isValid = false;
          }
        });
      } else {
        const check2 = this.getValueField("check2");
        if (check2) {
          error = "Aggiungere almeno un insegnamento";
          isValid = false;
        }
      }

      this.setState({ error });
    }
    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];
    this.payload = payloadBuilder(this.state);
    if (this.payload.check1) {
      payloadOther.push({
        qualificationCode: "title12",
        qualificationType: "CLaurea",
        classeConcorso: this.props.competitionClass,
        template: "Title12CLaurea",
      });
    }

    if (this.payload.check2) {
      payloadOther.push({
        qualificationCode: "title12",
        qualificationType: "CCFU",
        classeConcorso: this.props.competitionClass,
        template: "Title12CCFU",
      });

      if (this._formsInsegnamenti.length > 0) {
        this._formsInsegnamenti.forEach((form) => {
          if (form && form.current != null) {
            // get payload
            const payloadInsegnamento = form.current.getPayload();
            payloadOther.push(payloadInsegnamento);
          }
        });
      }
    }

    return payloadOther;
  };

  addInsegnamenti = () => {
    this.state.numInsegnamentiMultipli.push(
      this.state.numInsegnamentiMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numInsegnamentiMultipli: this.state.numInsegnamentiMultipli,
    });
  };

  removeInsegnamenti = (idx) => {
    if (this.state.numInsegnamentiMultipli.length <= 0) {
      return true;
    }

    this.state.numInsegnamentiMultipli.splice(idx, 1);
    this._formsInsegnamenti.splice(idx, 1);

    this.setState({
      ...this.state,
      numInsegnamentiMultipli: this.state.numInsegnamentiMultipli,
    });
  };

  render() {
    
    const { defaultValues, error } = this.state;
    const fields = this.props.competitionClass === "B012" ? this.FIELDS_GROUP_DIPLOMA : this.FIELDS_GROUP_LAUREA;

    const { code } = this.renderFields(
      fields,
      defaultValues,
      true
    );

    const check2 = this.getValueField("check2");

    return (
      <>
        {code}
        {check2 && (
          <>
            {(this.state.numInsegnamentiMultipli || []).map((_, idx) => {
              this._formsInsegnamenti[idx] = React.createRef();

              return (
                <div
                  key={`insegnamenti=${idx}`}
                  className="m-0 ml-5 text-secondary"
                >
                  <h6>Insegnamento n. {idx + 1}</h6>
                  <Button
                    className="mb-3 btn btn-sm btn-danger"
                    onClick={() => {
                      this.removeInsegnamenti(idx);
                    }}
                  >
                    Rimuovi
                  </Button>
                  <div className="p-3 border border-secondary rounded-lg">
                    <InsegnamentiMultiManager60CFU
                      ref={this._formsInsegnamenti[idx]}
                      key={`insegnamenti=${idx}`}
                      qualificationType={"title12CFU"}
                      idx={idx}
                    />
                  </div>
                </div>
              );
            })}
            <div className="mt-3 ml-5 text-secondary">
              <Button
                className="btn btn-sm btn-primary"
                onClick={this.addInsegnamenti}
              >
                aggiungi un insegnamento
              </Button>
            </div>
          </>
        )}
        {error !== "" && <div className="alert alert-danger m-3">{error}</div>}
      </>
    );
  }
}
